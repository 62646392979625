// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-admin-js": () => import("./../../../src/pages/accountAdmin.js" /* webpackChunkName: "component---src-pages-account-admin-js" */),
  "component---src-pages-charla-js": () => import("./../../../src/pages/charla.js" /* webpackChunkName: "component---src-pages-charla-js" */),
  "component---src-pages-chat-js": () => import("./../../../src/pages/chat.js" /* webpackChunkName: "component---src-pages-chat-js" */),
  "component---src-pages-helper-js": () => import("./../../../src/pages/helper.js" /* webpackChunkName: "component---src-pages-helper-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-search-js": () => import("./../../../src/pages/newsSearch.js" /* webpackChunkName: "component---src-pages-news-search-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-recover-password-js": () => import("./../../../src/pages/recover-password.js" /* webpackChunkName: "component---src-pages-recover-password-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-sales-agent-js": () => import("./../../../src/pages/salesAgent.js" /* webpackChunkName: "component---src-pages-sales-agent-js" */),
  "component---src-pages-youtube-summary-js": () => import("./../../../src/pages/youtubeSummary.js" /* webpackChunkName: "component---src-pages-youtube-summary-js" */)
}

